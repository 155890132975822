import ReactDOM from 'react-dom'
import { useSpring, animated } from '@react-spring/web'
import { useBodyScrollLock } from '/machinery/useBodyScrollLock'
import { usePortalNode } from '/machinery/usePortalNode'

import styles from './BenefitsPortal.css'

export function BenefitsPortal({ visible, children }) {
  const portalNode = usePortalNode(styles._rootComponent)
  const { ref: bodyScrollLockRef } = useBodyScrollLock({ enabled: visible })

  const portalSpring = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { duration: 1000 }
  })

  return portalNode && ReactDOM.createPortal(
    <animated.div style={portalSpring} className={styles.component} ref={bodyScrollLockRef} aria-hidden={visible}>
      {children}
    </animated.div>,
    portalNode
  )
}
