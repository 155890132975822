import { useWasInViewport } from '@kaliber/use-is-in-viewport'
import { useSpring, animated } from '@react-spring/web'
import { Icon } from '/features/buildingBlocks/Icon'
import { useKeenSlider } from 'keen-slider/react'
import { HeadingSm } from '/features/buildingBlocks/Heading'
import { track } from '/machinery/track'
import { ImageCover } from '/features/buildingBlocks/Image'

import arrowRight from '/images/icons/arrow-right-slider.raw.svg'
import arrowLeft from '/images/icons/arrow-left-slider.raw.svg'
import iconCross from '/images/icons/cross.raw.svg'
import iconRemote from '/images/icons/remote.raw.svg'
import iconGrowth from '/images/icons/growth.raw.svg'
import iconDiamond from '/images/icons/diamond.raw.svg'
import iconInspiration from '/images/icons/inspiration.raw.svg'

import styles from './BenefitsSlider.css'

export function BenefitsSlider({ items, onClose }) {
  const [currentSlide, setCurrentSlide] = React.useState(0)
  const [loaded, setLoaded] = React.useState(false)

  const [sliderRef, instanceRef] = useKeenSlider({
    initial: 0,
    slideChanged(s) {
      setCurrentSlide(s.track.details.rel)
      track({ event: 'interaction_start',
        metadata: {
          interaction: {
            type: 'slided',
            index: s.track.details.rel,
            title: items ? items[s.track.details.rel].title : null
          }
        }
      })
    },
    created() {
      setLoaded(true)
    },
  })

  return items && (
    <div className={cx(styles.component, 'navigation-wrapper')}>
      <div ref={sliderRef} className={cx(styles.slider, 'keen-slider')}>
        <ProgressBar layoutClassName={styles.progressBar} {... { items, currentSlide }} />
        {items.map(item => (
          <Slide key={item._key} image={item.image} title={item.title} text={item.text} icon={item.icon} />
        ))}
        {loaded && instanceRef.current && <Controls layoutClassName={styles.controls} {... { onClose, currentSlide, instanceRef }} />}
      </div>
    </div>
  )
}

function ProgressBar({ items, currentSlide, layoutClassName }) {
  return items && (
    <div className={cx(styles.componentProgressBar, layoutClassName)}>
      {items.map((item, i) => (
        <div key={item._key} className={cx(styles.progress, currentSlide === i && styles.isActive)} />
      ))}
    </div>
  )
}

function Slide({ image, title, text, icon }) {
  const { ref: elementRef, wasInViewport } = useWasInViewport({
    threshold: [1],
  })

  const springConfig = {
    to: {
      opacity: wasInViewport ? 1 : 0,
      transform: wasInViewport ? 'translateY(0)' : 'translateY(40px)'
    },
    config: { tension: 100, friction: 35 }
  }

  const scrollIndicatorSpring = useSpring({ ...springConfig })
  const headingSpring = useSpring({ ...springConfig, delay: 50 })
  const textSpring = useSpring({ ...springConfig, delay: 100 })

  return (
    <div className={cx(styles.componentSlide, 'keen-slider__slide')} ref={elementRef}>
      <div className={styles.content}>
        <div className={styles.contentInner}>
          <animated.div style={headingSpring} className={styles.heading}>
            <HeadingSm h='4'>{title}</HeadingSm>
          </animated.div>
          <animated.p style={textSpring} className={styles.text}>{text}</animated.p>
          <animated.div style={scrollIndicatorSpring} className={styles.slideIcon}><Icon icon={getSlideIcon()} layoutClassName={styles.icon} /></animated.div>
        </div>
      </div>
      <div className={styles.imageContainer}>
        {image && <ImageCover layoutClassName={styles.image} aspectRatio={1 / 1} {... { image }} />}
        <div className={styles.gradient} />
      </div>
    </div>
  )

  function getSlideIcon() {
    switch (icon) {
      case 'diamond':
        return iconDiamond
      case 'growth':
        return iconGrowth
      case 'inspiration':
        return iconInspiration
      default:
        return iconRemote
    }
  }
}

function Controls({ instanceRef, currentSlide, layoutClassName, onClose }) {
  return (
    <div className={cx(styles.componentControls, layoutClassName)}>
      <button
        data-x="go-to-previous-slide"
        disabled={currentSlide === 0}
        onClick={() => handleClick(currentSlide - 1)}
        type="button"
        className={cx(styles.arrow, styles.arrowLeft, currentSlide === 0 && styles.disabled)}
      >
        <Icon icon={arrowLeft} />
      </button>
      <button
        data-x="click-to-close-modal"
        className={styles.closeButton}
        onClick={() => onClose(false)}
        type='button'
      >
        <span className={styles.iconContainer}>
          <Icon layoutClassName={styles.iconCross} icon={iconCross} />
        </span>
      </button>
      <button
        type="button"
        data-x="go-to-next-slide"
        onClick={() => handleClick(currentSlide + 1)}
        disabled={currentSlide === instanceRef.current.slides.length - 1}
        className={cx(styles.arrow, styles.arrowRight, currentSlide === instanceRef.current.slides.length - 1 && styles.disabled )}
      >
        <Icon icon={arrowRight} />
      </button>
    </div>
  )
  function handleClick(index) {
    instanceRef.current.moveToIdx(index)
  }
}
